import { Button, Dialog, DialogContent, Stack, Typography } from "@mui/material";

import React from "react";

import { useTranslation } from "react-i18next";

/**
 * @param {object} props
 * @param {boolean} props.isOpen - boolean value whether dialogue is open
 * @param {string} props.headline - text to show at top of dialogue
 * @param {string} props.question - question to be asked inside dialogue
 * @param {Function} props.handleClose - function to handle closing dialogue
 * @param {Function} props.handleConfirm - function to call when 'OK' button is pressed
 * @param {object} props.confirmParams - parameters to pass to handleConfirm function
 * @returns {JSX.Element} dialogue when 'create new module' button is pressed to build module
 */
const ConfirmDialog = ({ isOpen, headline, question, handleClose, handleConfirm }) => {
    // i18n
    const { t } = useTranslation();

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogContent>
                <Stack direction="column">
                    {/* dialog header */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ marginBottom: "30px" }}
                    >
                        {/* dialog title */}
                        <Typography
                            variant="h3"
                            className="dialogHeadline"
                            sx={{ margin: "10px 0px 0px 10px !important" }}
                        >
                            {headline}
                        </Typography>
                    </Stack>

                    {/* module form */}
                    <Stack px="10px" spacing={5} mb={5}>
                        <Typography variant="body1" className="deleteQuestion">
                            {question}
                        </Typography>
                    </Stack>

                    <Stack direction="row" spacing={1} justifyContent="end">
                        <Button
                            variant="contained"
                            color="secondary"
                            className="module"
                            onClick={(event) => {
                                event.preventDefault();
                                handleConfirm();
                                setTimeout(() => {
                                    handleClose(event);
                                }, 500);
                            }}
                            sx={{ width: "max-content", alignSelf: "end" }}
                            aria-label="confirm"
                        >
                            {t("confirm")}
                        </Button>

                        <Button
                            variant="outlined"
                            color="secondary"
                            className="module"
                            onClick={(event) => {
                                event.preventDefault();
                                handleClose(event);
                            }}
                            sx={{ width: "max-content", alignSelf: "end" }}
                            aria-label="cancel"
                        >
                            {t("cancel")}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmDialog;
