import { Grid, Typography } from "@mui/material";

import { Link } from "react-router-dom";

import { formatCurrency, formatDateTime } from "../utils";

import Chip from "../components/HomepageChip";
import InvoiceOptions from "./InvoiceOptions";

/**
 * @param {object} props
 * @param {object} props.data - invoice data to be shown as row on homepage
 * @param {object[]} props.columns - array of current column objects
 * @param {boolean} props.changing - indicator to show when switching to new page
 * @param {Function} props.onReject - function to call when rejecting invoice
 * @returns {JSX.Element} customized row for homepage
 */
const HomepageGrid = ({ data, columns, changing, onReject }) => {
    return (
        <Link to={`/eInvoice/${data["DocumentId"]}`} className="homepage">
            <Grid item width="100%" sx={{ opacity: changing ? "0" : "1" }}>
                <Grid container className="home">
                    {columns.map((column) => {
                        if (column.FieldName !== "DocumentId") {
                            const value = data[column.FieldName];
                            if (column.FieldName === "DocumentStatus") {
                                return (
                                    <Grid
                                        key={column.FieldName}
                                        item
                                        className="home"
                                        md={11 / columns.length}
                                        lg={11.25 / columns.length}
                                        sx={{
                                            paddingLeft: "0px !important",
                                            paddingRight: "0px !important",
                                            justifyContent: "start !important"
                                        }}
                                    >
                                        <Chip statusCode={value} />
                                    </Grid>
                                );
                            } else {
                                return (
                                    <Grid
                                        key={column.FieldName}
                                        item
                                        className="home"
                                        md={11 / columns.length}
                                        lg={11.25 / columns.length}
                                    >
                                        <Typography variant="body1" className="home">
                                            {column["DataType"] === "datetime"
                                                ? formatDateTime(value)
                                                : column["DataType"] === "currency"
                                                ? formatCurrency(value)
                                                : value === "None"
                                                ? "\u00A0"
                                                : value}
                                        </Typography>
                                    </Grid>
                                );
                            }
                        }
                    })}
                    <Grid
                        item
                        className="home"
                        md={1}
                        lg={0.75}
                        sx={{ justifyContent: "end !important" }}
                    >
                        <InvoiceOptions data={data} onReject={onReject} />
                    </Grid>
                </Grid>
            </Grid>
        </Link>
    );
};

export default HomepageGrid;
