import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography
} from "@mui/material";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import mailPlaceholder from "../assets/card_placeholders/mail_placeholder.svg";
import sharepointPlaceholder from "../assets/card_placeholders/sharepoint_placeholder.svg";

import { useLocation } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { formatDateTime } from "../utils";
import TooltipButton from "./TooltipButton";

/**
 * @param {object} props
 * @param {object} props.moduleName - name of module
 * @param {object} props.creationTime - time of creation of module
 * @param {object} props.modificationTime - time of modification of module - else '-'
 * @param {object} props.config - config object of current module
 * @param {Function} props.handleOpen - function to open detail view of module
 * @param {Function} props.handleOpenEdit - function to open detail view and enable editing mode
 * @param {Function} props.handleServerStart - method to add name of module whose server has been started to local storage
 * @param {Function} props.handleDelete - function to delete module config
 * @param {string} props.ariaLabel - text to use for accessibility
 * @returns {JSX.Element} custom card shown on homepage to visualize different module configurations
 */

const ModuleCard = ({
  moduleName,
  creationTime,
  modificationTime,
  config,
  handleOpen,
  handleOpenEdit,
  handleServerStart,
  handleDelete,
  ariaLabel
}) => {
  // i18n
  const { t } = useTranslation();
  // authorization
  const { request } = useAuth();

  // variables for oAuth
  const location = useLocation();

  // endpoints in use
  const endpoint = `${process.env.REACT_APP_BACKEND_URL}/module`;

  const updateAuthorizationCode = async (body) => {
    const response = await request("put", endpoint, body, "application/json", [
      {
        statusCode: 200,
        type: "success",
        message: t("module_update_token_success", {
          module_name: moduleName
        })
      },
      {
        statusCode: 400,
        type: "error",
        message: t("module_update_token_failure", {
          module_name: moduleName
        })
      },
      {
        statusCode: 422,
        type: "warning",
        message: ["Module name", t("module_invalid_name"), t("module_invalid_config")]
      }
    ]);

    if (response.status === 200) {
      localStorage.removeItem("moduleToUpdate");
      resetUrl();
    }
  };

  // resets url after server starting to remove token param
  const resetUrl = () => {
    const currentURL = new URL(window.location.href);
    const params = new URLSearchParams(currentURL.search);
    params.delete("code");
    currentURL.search = "";
    window.history.replaceState({}, "", currentURL.toString());
  };

  useEffect(() => {
    if (moduleName == "Outlook-Service") {
      const urlParams = new URLSearchParams(window.location.search);

      // gets authorization code from url
      const authorizationCode = urlParams.get("code");

      // gets name of module that needs to be updatet from local storage
      const moduleToUpdate = localStorage.getItem("moduleToUpdate");
      if (authorizationCode && moduleName == "Outlook-Service") {
        const stateFromUrl = urlParams.get("state");
        const stateFromLocalStorage = localStorage.getItem("stateString");
        if (moduleName === "Outlook-Service") {
          if (stateFromLocalStorage === stateFromUrl) {
            console.log("State is correct");
            // copy config and update authorization code inside copy
            const requestConfig = JSON.parse(JSON.stringify(config));
            requestConfig.GRAPH_API.redeem = false;
            requestConfig.GRAPH_API.authorization_code = authorizationCode;

            // create request body with new data
            const body = {
              module_name: `${moduleToUpdate}`,
              configuration: requestConfig
            };

            updateAuthorizationCode(body);
          } else {
            console.log("Attention! state of returned token does not match!");
          }
        } else {
          console.log("No module to update");
        }
      } else {
        console.log("Token not found in URL");
        localStorage.removeItem("moduleToUpdate");
      }
    }
  }, [location]);

  if (moduleName && creationTime && modificationTime) {
    return (
      // wrapper to make whole card focusable using tab
      <div className="cardWrapper" tabIndex={0} role="group" aria-label={ariaLabel}>
        <Card>
          {/* card thumbnail */}
          <CardMedia
            component="img"
            image={
              moduleName.toLowerCase().includes("outlook") ? mailPlaceholder : sharepointPlaceholder
            }
            title={moduleName}
          />

          {/* clickable area to open detail view */}
          <CardActionArea
            onClick={handleOpen}
            aria-label="open current module configuration detail view"
          >
            <CardContent>
              {/* module name */}
              <Typography variant="h6" className="cardHeadline">
                {moduleName}
              </Typography>

              {/* creation time */}
              <Typography variant="body1" className="cardContent">
                {`${t("module_CreateTimestamp")}: ${
                  creationTime !== "None" ? formatDateTime(creationTime) : "-"
                }`}
              </Typography>

              {/* modification time */}
              <Typography variant="body1" className="cardContent">
                {`${t("module_ChangeTimestamp")}: ${
                  modificationTime !== "None" ? formatDateTime(modificationTime) : "-"
                }`}
              </Typography>
            </CardContent>
          </CardActionArea>

          {/* card buttons */}
          <CardActions>
            {moduleName.toLowerCase().includes("outlook") ? (
              <Button
                variant="contained"
                color="primary"
                className="module"
                onClick={() => {
                  handleServerStart();
                }}
                sx={{ alignSelf: "end" }}
              >
                {t("oauth")}
              </Button>
            ) : (
              <Box></Box>
            )}

            <Box>
              {/* edit button */}
              <TooltipButton
                tooltip={`${t("edit")}`}
                handleClick={handleOpenEdit}
                icon="edit"
                ariaLabel="edit current configuration"
                buttonClass="cardIcon"
                iconClass="cardIcon"
              />

              {/* delete button */}
              <TooltipButton
                tooltip={`${t("delete")}`}
                handleClick={handleDelete}
                icon="delete"
                ariaLabel="delete current module"
                buttonClass="cardIcon"
                iconClass="cardIcon"
              />
            </Box>
          </CardActions>
        </Card>
      </div>
    );
  }
};

export default ModuleCard;
