import { Grid, Stack, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import DateRangeColumnFilter from "./DatePickFilter";
import StatusFilter from "./DropdownFilter";
import DefaultColumnFilter from "./TextFilter";
import TooltipButton from "./TooltipButton";

/**
 * @param {Object} props
 * @param {Element} props.children - children component(s)
 * @param {Object[]} props.columns - array of current column objects
 * @param {string} props.sortColumn - name of column currently sorted by
 * @param {string} props.sortDirection - "ASC" or "DESC"
 * @param {Function} props.onSortChange - function to call when sort direction or column is changed
 * @param {any[]} props.filter - array of current filter values
 * @param {Function} props.onFilterChange - function to call when filter values are being changed
 * @param {Function} props.onEnter - function to call when enter is being clicked inside filter textfield
 * @param {Function} props.onClearFilter - function to call when clearing filter
 * @returns {JSX.Element} - table header for homepage
 */
const HomepageColumnHeader = ({
  children,
  columns,
  sortColumn,
  sortDirection,
  onSortChange,
  filter,
  onFilterChange,
  onEnter,
  onClearFilter
}) => {
  // i18n
  const { t } = useTranslation(["translation", "fieldnames"]);

  return (
    <>
      {columns.map((column, index) => (
        <Grid
          item
          md={11 / columns.length}
          lg={11.25 / columns.length}
          className="homeHead top"
          style={{
            display: "flex",
            flexDirection: "row"
          }}
          key={index}
        >
          <Stack direction="row" justifyContent="space-between" gap={1} alignItems="center">
            <Typography variant="h2" className="homeHead">
              {t(column.FieldName, { ns: "fieldnames" })}
            </Typography>
            {sortColumn === column.FieldName ? (
              <TooltipButton
                icon={sortDirection === "DESC" ? "arrowdown" : "arrowup"}
                handleClick={() => onSortChange(column.FieldName)}
                iconStyle={{
                  color: "white",
                  fontSize: "22px",
                  opacity: sortColumn === column.FieldName ? "1" : "0.25",
                  "&:hover": {
                    opacity: "1",
                    transition: "0.3s"
                  }
                }}
              />
            ) : (
              <TooltipButton
                icon="arrowdown"
                handleClick={() => onSortChange(column.FieldName)}
                iconStyle={{
                  color: "white",
                  fontSize: "22px",
                  opacity: "0.25",
                  "&:hover": {
                    opacity: "1",
                    transition: "0.3s"
                  }
                }}
              />
            )}
          </Stack>
        </Grid>
      ))}
      {children}
      {columns.map((column, index) => (
        <Grid
          item
          md={11 / columns.length}
          lg={11.25 / columns.length}
          className="homeHead bottom"
          style={{
            display: "flex",
            flexDirection: "row"
          }}
          key={index}
        >
          {column.FieldName === "DocumentStatus" ? (
            <StatusFilter
              value={filter[index]}
              onChange={onFilterChange}
              index={index}
              onEnter={onEnter}
            />
          ) : column.DataType === "datetime" ? (
            <DateRangeColumnFilter
              value={filter[index]}
              onChange={onFilterChange}
              index={index}
              onEnter={onEnter}
            />
          ) : (
            <DefaultColumnFilter
              value={filter[index]}
              onChange={onFilterChange}
              index={index}
              onEnter={onEnter}
            />
          )}
        </Grid>
      ))}
      <Grid
        item
        md={1}
        lg={0.75}
        className="homeHead bottom"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end"
        }}
      >
        <TooltipButton
          icon="clear_filter"
          tooltip={t("clear_filter")}
          iconStyle={{
            color: "white"
          }}
          buttonStyle={{
            height: "min-content",
            "&.Mui-disabled": { "& svg.MuiSvgIcon-root": { opacity: "0.3" } }
          }}
          handleClick={onClearFilter}
          disabled={filter.every((value) => {
            return value === "";
          })}
        />
      </Grid>
    </>
  );
};

export default HomepageColumnHeader;
