import { Chip } from "@mui/material";

import { useTranslation } from "react-i18next";
import CustomTooltip from "./CustomTooltip";

/**
 * @param {object} props
 * @param {string} statusCode - document status code as string
 * @returns {JSX.Element} custom chip for homepage
 */

const HomepageChip = ({ statusCode }) => {
    // i18n
    const { t } = useTranslation("fieldnames");

    const getStatusClass = (statusCode) => {
        switch (statusCode) {
            case "100":
                return "initial";
            case "101":
                return "accepted";
            case "102":
                return "ready";
            case "200":
                return "processed";
            case "201":
            case "400":
                return "rejected";
            case "401":
            case "402":
            case "500":
                return "error";
            default:
                return "";
        }
    };

    const getTranslation = (statusCode) => {
        if (parseInt(statusCode) > 400 && parseInt(statusCode) <= 402) {
            return "40*";
        } else {
            return statusCode;
        }
    };

    return (
        <CustomTooltip title={t(`status_${getTranslation(statusCode)}`.toLowerCase())}>
            <Chip
                className={`homepage ${getStatusClass(statusCode)}`}
                label={t(`status_${getTranslation(statusCode)}`.toLowerCase())}
            />
        </CustomTooltip>
    );
};

export default HomepageChip;
