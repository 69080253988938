import { createTheme } from "@mui/material/styles";

// set of pre-defined colors for webservice
const colors = createTheme({
  palette: {
    background_light: {
      main: "#eeeeee",
      highlight: "#efefef",
      shadow: "#d7d7d7"
    },
    background: {
      main: "#282c34",
      highlight: "#474a52",
      shadow: "#15181c"
    },
    primary: {
      main: "#FF791E",
      hover_light: "#FF9349",
      hover_dark: "#D65701",
      highlight: "#FFAC73",
      shadow: "#A84400"
    },
    secondary: {
      main: "#2256A9",
      hover_light: "#406DB4",
      hover_dark: "#0D3F8E",
      highlight: "#6f9bde",
      shadow: "#09306F"
    },
    tertiary: {
      main: "#13A28A",
      hover_light: "#32AE99",
      hover_dark: "#008871",
      highlight: "#58C4B2",
      shadow: "#006B59"
    },
    complementary: {
      main: "#FFAD1E",
      hover_light: "#FFBD49",
      hover_dark: "#D68901",
      highlight: "#FFCC73",
      shadow: "#A86B00"
    }
  }
});

// customization of MUI components
const defaultTheme = createTheme({
  typography: {
    fontFamily: ["Poppins", "Roboto", "Helvetica", "Arial", "sans-serif"].join(",")
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1400,
      xl: 2000,
      xxl: 2100
    }
  }
});

defaultTheme.components = {
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontWeight: "bold",
        [defaultTheme.breakpoints.only("md")]: { fontSize: "30px" },
        [defaultTheme.breakpoints.only("lg")]: { fontSize: "38px" },
        [defaultTheme.breakpoints.only("xl")]: { fontSize: "32px" },
        [defaultTheme.breakpoints.only("xxl")]: { fontSize: "38px" },
        fontSize: "26px",
        "&.pageHeadline": {
          marginBottom: "40px",
          marginTop: "30px"
        }
      },
      h2: {
        fontWeight: "bold",
        "&.homeHead": {
          [defaultTheme.breakpoints.only("md")]: { fontSize: "12px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "16px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "14px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "16px"
          },
          paddingTop: "4px",
          fontSize: "10px"
        },
        "&.signin": {
          [defaultTheme.breakpoints.only("md")]: { fontSize: "24px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "32px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "30px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "34px"
          },
          fontSize: "10px"
        },
        "&.profile": {
          [defaultTheme.breakpoints.only("md")]: { fontSize: "28px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "22px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "20px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "22px"
          },
          fontSize: "20px",
          marginBottom: "25px"
        }
      },
      h3: {
        "&.dialogHeadline": {
          color: colors.palette.secondary.highlight,
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "26px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "22px"
          },
          fontSize: "20px",
          fontWeight: "bold"
        },
        "&.detailHeadline": {
          [defaultTheme.breakpoints.only("md")]: {
            fontSize: "14px",
            padding: "7px 0px 10px 10px"
          },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "20px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "15px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "18px"
          },
          fontSize: "12px",
          fontWeight: "bold",
          color: "black",
          padding: "10px 0px 16px 10px"
        }
      },
      h5: {
        "&.objectHeadline": {
          fontWeight: "bold",
          [defaultTheme.breakpoints.only("md")]: { fontSize: "18px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "20px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "16px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "18px"
          }
        }
      },
      h6: {
        "&.cardHeadline": {
          color: colors.palette.secondary.highlight,
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "22px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "16px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "18px"
          },
          fontSize: "18px",
          fontWeight: "bold"
        }
      },
      body1: {
        "&.navbar": {
          fontSize: "14px",
          [defaultTheme.breakpoints.only("md")]: { fontSize: "14px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "20px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "14px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "16px"
          }
        },
        "&.cardContent": {
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "18px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "13px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "15px"
          },
          fontSize: "15px",
          paddingTop: "10px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        },
        "&.detailView": {
          "&.key": {
            color: colors.palette.secondary.highlight,
            fontWeight: "bold"
          },
          "&.value": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          },
          [defaultTheme.breakpoints.only("md")]: { fontSize: "16px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "20px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "16px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "18px"
          },
          fontSize: "14px",
          paddingTop: "20px"
        },
        "&.home": {
          [defaultTheme.breakpoints.only("md")]: { fontSize: "13px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "18px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "14px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "16px"
          },
          fontSize: "10px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        },
        "&.detailText": {
          [defaultTheme.breakpoints.only("md")]: { fontSize: "12px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "18px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "13px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "16px"
          },
          fontSize: "10px"
        },
        "&.createModule": {
          color: colors.palette.secondary.highlight,
          fontWeight: "bold",
          [defaultTheme.breakpoints.only("md")]: { fontSize: "24px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "28px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "20px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "24px"
          }
        },
        "&.deleteQuestion": {
          [defaultTheme.breakpoints.only("md")]: { fontSize: "18px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "24px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "18px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "20px"
          },
          fontSize: "10px"
        },
        "&.items": {
          [defaultTheme.breakpoints.only("md")]: {
            fontSize: "10px"
          },
          [defaultTheme.breakpoints.only("lg")]: {
            fontSize: "14px"
          },
          [defaultTheme.breakpoints.only("xl")]: {
            fontSize: "13px"
          },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "16px"
          }
        }
      }
    }
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        "&.logs": {
          borderRadius: "0px",
          "&.Mui-expanded": {
            borderTop: "1px lightgrey solid",
            margin: "unset"
          }
        }
      }
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        "&.items": {
          minHeight: "unset",
          [defaultTheme.breakpoints.only("md")]: {
            fontSize: "10px",
            padding: "14px"
          },
          [defaultTheme.breakpoints.only("lg")]: {
            fontSize: "14px",
            padding: "15px"
          },
          [defaultTheme.breakpoints.only("xl")]: {
            fontSize: "13px",
            padding: "16px"
          },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "16px",
            padding: "18px"
          }
        }
      },
      content: {
        margin: "unset",
        "&.Mui-expanded": {
          margin: "unset"
        }
      }
    }
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: "14px",
        boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)"
      }
    }
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: colors.palette.primary.main
      }
    }
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        "&.lock": {
          backgroundColor: colors.palette.primary.main,
          margin: 10,
          width: 40,
          [defaultTheme.breakpoints.only("md")]: {
            margin: 20,
            height: 50,
            width: 50
          },
          [defaultTheme.breakpoints.only("lg")]: {
            margin: 20,
            height: 60,
            width: 60
          },
          [defaultTheme.breakpoints.only("xl")]: {
            margin: 15,
            height: 50,
            width: 50
          },
          [defaultTheme.breakpoints.only("xxl")]: {
            margin: 17,
            height: 60,
            width: 60
          }
        }
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      colorPrimary: {
        backgroundColor: colors.palette.primary.main
      },
      colorSecondary: {
        backgroundColor: colors.palette.secondary.main
      },
      containedPrimary: {
        backgroundColor: colors.palette.primary.main
      },
      containedSecondary: {
        backgroundColor: colors.palette.secondary.main
      },
      text: {
        color: "white",
        "&.MuiButton-colorPrimary:hover": {
          backgroundColor: colors.palette.primary.hover_dark
        },
        "&.MuiButton-colorSecondary:hover": {
          backgroundColor: colors.palette.secondary.hover_dark
        },
        "&.pagination": {
          minWidth: "40px",
          borderRadius: "100%",
          "&:hover": {
            backgroundColor: "#ffffff33"
          },
          "&.Mui-disabled": {
            color: "#ffffff33"
          }
        }
      },
      outlined: {
        borderColor: "transparent",
        color: "white",
        "&.MuiButton-colorPrimary:hover": {
          backgroundColor: colors.palette.primary.hover_dark
        },
        "&.MuiButton-colorSecondary": {
          "&.createModule": {
            backgroundColor: colors.palette.secondary.highlight + "33"
          }
        },
        "&.MuiButton-colorSecondary:hover": {
          backgroundColor: colors.palette.secondary.hover_dark,
          "&.createModule": {
            transition: "0.3s",
            backgroundColor: colors.palette.secondary.highlight + "4D",
            borderColor: "transparent"
          },
          "&.module": {
            transition: "0.3s",
            backgroundColor: colors.palette.secondary.highlight + "4D",
            borderColor: "transparent"
          }
        }
      },
      root: {
        paddingTop: "7px",
        "&.cardWrapper": {
          padding: "0px !important",
          margin: "0px !important",
          borderRadius: "25px",
          cursor: "default",
          backgroundColor: "transparent",
          textAlign: "left",
          Width: "auto",
          height: "auto"
        },
        "&.invAttachment": {
          [defaultTheme.breakpoints.only("md")]: { fontSize: "11px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "15px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "14px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "17px"
          },
          "&.unblock": {
            margin: "30px 0px 40px 20px"
          }
        },
        "&.logs": {
          padding: "8px 10px"
        },
        "&.module": {
          borderRadius: "15px",
          padding: "8px 18px",
          [defaultTheme.breakpoints.only("md")]: { fontSize: "14px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "16px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "12px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "14px"
          }
        },
        "&.MuiButton-colorPrimary:hover": {
          backgroundColor: colors.palette.primary.hover_dark
        },
        "&.MuiButton-colorSecondary:hover": {
          backgroundColor: colors.palette.secondary.hover_dark
        }
      }
    }
  },
  MuiButtonGroup: {
    styleOverrides: {
      groupedOutlined: { border: "none !important" },
      firstButton: {
        borderColor: "white",
        "&.language": {
          borderRight: "1px solid white !important"
        }
      },
      grouped: {
        "&.MuiButton-colorPrimary:hover": {
          backgroundColor: colors.palette.primary.hover_dark
        },
        "&.MuiButton-colorSecondary:hover": {
          backgroundColor: colors.palette.secondary.hover_dark
        }
      },
      lastButton: { marginLeft: 0 }
    }
  },
  MuiCard: {
    styleOverrides: {
      root: {
        padding: "15px",
        borderRadius: "25px",
        backgroundColor: colors.palette.background.highlight,
        boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.3)",
        color: "white"
      }
    }
  },
  MuiCardActions: {
    styleOverrides: {
      root: { padding: 0, justifyContent: "space-between" }
    }
  },
  MuiCardActionArea: {
    styleOverrides: {
      root: {
        borderRadius: "10px",
        padding: "10px",
        margin: "10px 0px 5px 0px"
      }
    }
  },
  MuiCardContent: {
    styleOverrides: {
      root: { padding: 0 }
    }
  },
  MuiCardMedia: {
    styleOverrides: {
      img: {
        aspectRatio: "5/3",
        borderRadius: "10px",
        boxShadow: "8px 8px 15px -8px rgba(0,0,0,0.3)"
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      root: {
        "&.homepage": {
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "13px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "11px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "13px"
          },
          fontSize: "11px",
          fontWeight: "bold",
          height: "unset",
          marginLeft: "15px",
          "&.initial": { backgroundColor: "#D3D3D3" },
          "&.accepted": { backgroundColor: "#00FF00" },
          "&.ready": { backgroundColor: "#1E90FF" },
          "&.processed": { backgroundColor: "#32CD32" },
          "&.rejected": {
            backgroundColor: "#FF4500",
            color: "white"
          },
          "&.error": { backgroundColor: "#FF0000", color: "white" },
          backgroundColor: "#808080"
        }
      },
      filledWarning: { backgroundColor: "#c48622" },
      filledSuccess: { backgroundColor: "#539144" },
      label: { cursor: "default", padding: "6px 10px 5px" }
    }
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        [defaultTheme.breakpoints.up("lg")]: { maxWidth: "unset" },
        "&.main": {
          backgroundColor: colors.palette.background.main,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          fontSize: "calc(10px + 2vmin)",
          color: "white",
          padding: "50px",
          width: "100%",
          maxWidth: "unset",
          minHeight: "100vh"
        },
        "&.detailGroup": {
          borderRadius: "25px",
          backgroundColor: colors.palette.background_light.shadow,
          padding: "15px 20px !important"
        },
        "&.detailText": {
          height: "min-content",
          width: "auto",
          display: "flex",
          backgroundColor: "#fefefe",
          padding: "10px 12px",
          borderRadius: "4px"
          // border: '1.5px solid #707070',
        },
        "&.jsonViewer": {
          padding: "10px",
          backgroundColor: colors.palette.background_light.main,
          borderRadius: "15px",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          "&.off": {
            backgroundColor: colors.palette.background_light.main + "E6"
          }
        },
        "&.logs": {
          width: "100%",
          padding: "unset",
          maxHeight: "500px",
          overflowY: "scroll",
          borderRadius: "10px 0px 0px 10px !important"
        },
        "&.logsFilter": {
          "&.empty": {
            backgroundColor: "white",
            color: "black",
            padding: "14px"
          },
          backgroundColor: colors.palette.background_light.main,
          width: "100%",
          padding: "unset",
          display: "flex",
          alignItems: "center",
          padding: "10px"
        }
      }
    }
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        backgroundColor: "#fff",
        border: "1px solid #ccc"
      },
      cell: {
        color: "#333 !important"
      },
      row: {
        "&:hover": {
          backgroundColor: "#f5f5f5"
        }
      },
      columnHeaderTitle: {
        fontWeight: "bold"
      },
      columnHeader: {
        backgroundColor: colors.palette.background_light.highlight,
        color: "#000"
      }
    }
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        "&.module": {
          [defaultTheme.breakpoints.up("md")]: { minWidth: "60%" },
          [defaultTheme.breakpoints.up("lg")]: { minWidth: "50%" },
          [defaultTheme.breakpoints.up("xl")]: { minWidth: "45%" },
          [defaultTheme.breakpoints.up("xxl")]: { minWidth: "45%" }
        },
        borderRadius: "25px !important",
        backgroundColor: "unset"
      }
    }
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: "15px",
        borderRadius: "25px",
        backgroundColor: colors.palette.background.highlight,
        boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.3)",
        color: "white"
      }
    }
  },
  MuiGrid: {
    styleOverrides: {
      container: {
        "&.homeHead": {
          paddingLeft: "18px",
          backgroundColor: colors.palette.secondary.main + "B3",
          borderRadius: "40px",
          marginBottom: "15px",
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.3)"
        },
        "&.home": {
          paddingLeft: "18px",
          backgroundColor: colors.palette.background.highlight + "80",
          borderRadius: "40px",
          marginBottom: "15px",
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.3)",
          "&:hover": {
            backgroundColor: colors.palette.background.highlight + "E6"
          }
        },
        "&.module": {
          width: "100%",
          marginLeft: "-24px !important"
        }
      },
      item: {
        "&.homeHead": {
          padding: "0px 20px",
          "&.top": {
            paddingTop: "22px"
          },
          "&.bottom": {
            paddingBottom: "25px"
          }
        },
        "&.home": {
          "&:first-of-type": { paddingLeft: "22px" },
          padding: "25px 20px",
          display: "flex",
          justifyContent: "start",
          alignItems: "center"
        }
      }
    }
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        "&.cardIcon": {
          color: colors.palette.primary.highlight,
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.06)",
            transition: "0.3s"
          }
        },
        "&.detailIcon": {
          color: "white"
        }
      }
    }
  },
  MuiInput: {
    styleOverrides: {
      input: {
        color: "white",
        [defaultTheme.breakpoints.only("lg")]: { fontSize: "22px" },
        [defaultTheme.breakpoints.only("xxl")]: { fontSize: "18px" },
        fontSize: "16px",
        "&.Mui-disabled": {
          color: "lightgray !important",
          WebkitTextFillColor: "unset"
        }
      },
      root: {
        "&::after": {
          borderBottom: "2px solid " + colors.palette.primary.hover_light
        }
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        "&.Mui-disabled": {
          color: "black",
          WebkitTextFillColor: "unset"
        },
        color: "white"
        // fontSize: '14px',
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        [defaultTheme.breakpoints.only("lg")]: { fontSize: "18px" },
        [defaultTheme.breakpoints.only("xxl")]: { fontSize: "18px" },
        fontSize: "16px",
        color: "white",
        "&.MuiInputLabel-outlined": {
          fontWeight: "normal"
        },
        fontWeight: "bold",
        "&.Mui-focused": {
          color: colors.palette.secondary.highlight,
          fontWeight: "bold"
        },
        "&.Mui-disabled": {
          color: "gray"
        }
      }
    }
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: "none",
        "&.signin": {
          textDecoration: "underline",
          color: colors.palette.secondary.highlight,
          [defaultTheme.breakpoints.only("md")]: { fontSize: "16px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "18px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "14px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "18px"
          },
          fontSize: "12px",
          "&:hover": {
            color: colors.palette.secondary.hover_light
          }
        }
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        border: "2px solid #c0c0c0"
      },
      root: {
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#c0c0c080"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: colors.palette.secondary.highlight + " !important"
        }
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        "&.MuiDialog-paper": {
          [defaultTheme.breakpoints.up("md")]: { minWidth: "60%" },
          [defaultTheme.breakpoints.up("lg")]: { minWidth: "50%" },
          [defaultTheme.breakpoints.up("xl")]: { minWidth: "45%" },
          [defaultTheme.breakpoints.up("xxl")]: { minWidth: "45%" }
        }
      }
    }
  },
  MuiStack: {
    styleOverrides: {
      root: {
        "&.createModule": {
          height: "calc(85% - 15px)",
          width: "calc(85% - 15px)",
          alignItems: "center",
          alignSelf: "center",
          justifyContent: "center",
          border: "4px dotted " + colors.palette.secondary.highlight,
          borderRadius: "15px",
          padding: "15px"
        }
      }
    }
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        transition: "0.3s",
        "&.cardIcon": {
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "30px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "24px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "28px"
          },
          fontSize: "24px"
        },
        "&.loginIcon": {
          fontSize: "35px",
          [defaultTheme.breakpoints.only("md")]: { fontSize: "40px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "50px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "40px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "50px"
          },
          color: colors.palette.primary.shadow
        },
        "&.listIcon": {
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "26px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "20px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "22px"
          },
          fontSize: "20px"
        },
        "&.lock": {
          [defaultTheme.breakpoints.only("md")]: { fontSize: "28px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "32px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "28px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "32px"
          },
          fontSize: "20px"
        },
        "&.createModule": {
          color: colors.palette.secondary.highlight,
          [defaultTheme.breakpoints.only("md")]: {
            fontSize: "160px"
          },
          [defaultTheme.breakpoints.only("lg")]: {
            fontSize: "160px"
          },
          [defaultTheme.breakpoints.only("xl")]: {
            fontSize: "120px"
          },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "140px"
          },
          fontSize: "20px"
        },
        "&.level": {
          [defaultTheme.breakpoints.only("md")]: {
            fontSize: "15px"
          },
          [defaultTheme.breakpoints.only("lg")]: {
            fontSize: "20px"
          },
          [defaultTheme.breakpoints.only("xl")]: {
            fontSize: "20px"
          },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "22px"
          },
          fontSize: "10px",
          "&.success": {
            color: "rgb(102, 187, 106)"
          },
          "&.info": {
            color: "rgb(41, 182, 246)"
          },
          "&.warning": {
            color: "rgb(255, 167, 38)"
          },
          "&.error": {
            color: "rgb(244, 67, 54)"
          }
        },
        "&.userMenu": {
          color: "grey",
          [defaultTheme.breakpoints.only("md")]: {
            fontSize: "22px"
          },
          [defaultTheme.breakpoints.only("lg")]: {
            fontSize: "22px"
          },
          [defaultTheme.breakpoints.only("xl")]: {
            fontSize: "20px"
          },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "18px"
          },
          fontSize: "20px"
        },
        "&.MuiSelect-icon": {
          fill: "white"
        }
      }
    }
  },
  MuiTable: {
    styleOverrides: {
      root: {
        color: "black",
        "&.detailGroup": {
          backgroundColor: colors.palette.background_light.main,
          marginTop: "8px",
          borderRadius: "10px"
        },
        "&.invoiceItems": {
          backgroundColor: "#fefefe",
          marginTop: "8px",
          borderRadius: "10px",
          minWidth: "100%"
        }
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: "none",
        "&.detailGroup": {
          padding: "10px 7px 10px 15px !important"
        },
        "&.bilDetL": {
          [defaultTheme.breakpoints.only("md")]: { width: "35%" },
          [defaultTheme.breakpoints.only("lg")]: { width: "35%" },
          [defaultTheme.breakpoints.only("xl")]: { width: "35%" },
          [defaultTheme.breakpoints.only("xxl")]: { width: "30%" }
        },
        "&.finTaxL": {
          [defaultTheme.breakpoints.only("md")]: { width: "60%" },
          [defaultTheme.breakpoints.only("lg")]: { width: "60%" },
          [defaultTheme.breakpoints.only("xl")]: { width: "50%" },
          [defaultTheme.breakpoints.only("xxl")]: { width: "50%" }
        },
        "&.finTaxR": {
          // [defaultTheme.breakpoints.only('md')]: { width: '80px' },
          // [defaultTheme.breakpoints.only('xl')]: { width: '90px' },
          // [defaultTheme.breakpoints.only('xxl')]: { width: '130px' },
        },
        "&.items": {
          [defaultTheme.breakpoints.only("md")]: {
            fontSize: "10px",
            padding: "14px"
          },
          [defaultTheme.breakpoints.only("lg")]: {
            fontSize: "14px",
            padding: "15px"
          },
          [defaultTheme.breakpoints.only("xl")]: {
            fontSize: "13px",
            padding: "16px"
          },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "16px",
            padding: "18px"
          },
          fontSize: "10px",
          lineHeight: "unset",
          "&.itemHead": {
            [defaultTheme.breakpoints.only("md")]: {
              fontSize: "10px",
              padding: "10px 0px 10px 14px"
            }
          },
          "&.id": {
            overflow: "wrap",
            overflowWrap: "break-word"
          }
        }
      },
      head: {
        "&.itemHead": {
          "&:first-of-type": {
            borderRadius: "10px 0px 0px 0px !important"
          },
          "&:last-of-type": {
            borderRadius: "0px 10px 0px 0px !important"
          },
          backgroundColor: colors.palette.background_light.main,
          fontWeight: "bold",

          // invoice items
          "&.pos": {
            [defaultTheme.breakpoints.only("md")]: { width: "7%" },
            [defaultTheme.breakpoints.up("lg")]: { width: "3%" }
          },
          "&.unit": {
            [defaultTheme.breakpoints.only("md")]: { width: "14%" },
            [defaultTheme.breakpoints.only("lg")]: { width: "12%" }
          },
          "&.quan": {
            [defaultTheme.breakpoints.only("md")]: { width: "14%" },
            [defaultTheme.breakpoints.only("lg")]: { width: "12%" }
          },
          "&.net": {
            [defaultTheme.breakpoints.only("md")]: { width: "15%" },
            [defaultTheme.breakpoints.only("lg")]: { width: "12%" }
          },
          "&.rate": {
            [defaultTheme.breakpoints.up("md")]: { width: "15%" }
          },

          // link details
          "&.type": {
            [defaultTheme.breakpoints.up("xs")]: { width: "20%" },
            [defaultTheme.breakpoints.up("lg")]: { width: "15%" }
          },
          "&.id": {
            [defaultTheme.breakpoints.only("md")]: { width: "50%" },
            [defaultTheme.breakpoints.up("lg")]: { width: "55%" }
          },
          "&.time": {
            [defaultTheme.breakpoints.only("md")]: { width: "30%" },
            [defaultTheme.breakpoints.up("lg")]: { width: "30%" }
          },

          //user management
          "&.name": {
            [defaultTheme.breakpoints.up("md")]: { width: "20%" }
          },
          "&.role": {
            [defaultTheme.breakpoints.up("md")]: { width: "15%" }
          },
          "&.api": {
            [defaultTheme.breakpoints.up("md")]: { width: "10%" }
          },
          "&.action": {
            [defaultTheme.breakpoints.up("md")]: { width: "5%" }
          }
        }
      }
    }
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        "&.itemRow": {
          borderTop: "1px solid #e0e0e0"
        }
      }
    }
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        minWidth: "100%",
        color: "white",
        "&.textfilter": {
          width: "80%",
          marginTop: "8px"
        },
        "&.datepicker": {
          "&.MuiFormControl-root": {
            minWidth: "50%"
          }
        },
        "&.profile": {
          "& input.Mui-disabled": {
            color: "#ffffffdb"
          },
          "& label.Mui-disabled": {
            color: "white"
          },
          "& fieldset": {
            borderColor: "#c0c0c0 !important",
            border: "2px solid #c0c0c0"
          }
        }
      }
    }
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        "&.logs": {
          [defaultTheme.breakpoints.only("md")]: { fontSize: "11px" },
          [defaultTheme.breakpoints.only("lg")]: { fontSize: "15px" },
          [defaultTheme.breakpoints.only("xl")]: { fontSize: "14px" },
          [defaultTheme.breakpoints.only("xxl")]: {
            fontSize: "17px"
          },
          padding: "6px 12px",
          "&.Mui-selected": {
            backgroundColor: "rgba(0, 0, 0, 0.05)"
          }
        },
        "&.viewButton": {
          "&:hover": {
            backgroundColor: "#533f1d !important",
            color: "#a36d10",
            transition: "0.4s"
          },
          "&.Mui-selected": {
            backgroundColor: "#533f1d !important",
            color: "#a36d10"
          },
          backgroundColor: "#3e372a",
          color: "#6e5428",
          padding: "8px"
        }
      }
    }
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        "&.logs": {
          backgroundColor: "white"
        }
      }
    }
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        [defaultTheme.breakpoints.up("xl")]: {
          paddingLeft: "0px !important",
          paddingRight: "0px !important",
          width: "69%",
          alignSelf: "center"
        }
      }
    }
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: colors.palette.background.shadow,
        [defaultTheme.breakpoints.only("lg")]: { fontSize: "15px" },
        [defaultTheme.breakpoints.only("xl")]: { fontSize: "12px" },
        [defaultTheme.breakpoints.only("xxl")]: { fontSize: "15px" }
      }
    }
  }
};

export default defaultTheme;
