import { Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

/**
 * @param {object} props
 * @param {number} props.currentPage - page currently shown
 * @param {number} props.selectedPage - page number selected in pagination
 * @param {boolean} props.changing - state showing whether page is changing
 * @param {string} props.filterValue - value for filter input
 * @param {number} props.totalPages - number of total pages
 * @param {function} props.onPageChange - called when page is changing
 * @param {function} props.onFilterChange - called when filter value is changing
 * @param {function} props.onEnter - called when enter is pressed inside filter input
 * @returns {JSX.Element} - custom pagination component
 */
const Pagination = ({
    currentPage,
    selectedPage,
    changing,
    filterValue,
    totalPages,
    onPageChange,
    onFilterChange,
    onEnter
}) => {
    // i18n
    const { t } = useTranslation();

    const [activeLinkClassName, setActiveLinkClassName] = useState("");

    // function to handle pagination button click
    const handlePageChange = (event) => {
        onPageChange(event.selected + 1, true);
        onFilterChange("");
    };

    // function to handle filter input
    const handleFilterChange = async (event) => {
        const newPage = event.target.value;
        const totalPageLength = totalPages.toString().length;
        const regex = /^\d+$/;
        if (
            newPage.length > totalPageLength ||
            parseInt(newPage) > totalPages ||
            !newPage.match(regex)
        ) {
            onFilterChange("");
        } else {
            onFilterChange(newPage);
            onPageChange(parseInt(newPage));
        }
    };

    // check whether current page equals page selected to show
    const checkPage = () => {
        return currentPage === selectedPage;
    };

    // adjust active page button style based on current state
    useEffect(() => {
        if (checkPage()) {
            setActiveLinkClassName("active");
        } else {
            setActiveLinkClassName("");
        }
    }, [changing]);

    return (
        <Stack direction="row" width="100%" justifyContent="center" position="relative">
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                previousLabel="<"
                pageCount={totalPages}
                forcePage={currentPage - 1}
                onPageChange={(event) => {
                    handlePageChange(event);
                }}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                className="pagination"
                pageLinkClassName="pagination"
                activeLinkClassName={activeLinkClassName}
                breakLinkClassName="pagination"
                previousLinkClassName="pagination"
                nextLinkClassName="pagination"
                disabledLinkClassName="disabled"
            />
            {totalPages > 1 && (
                <Stack
                    minWidth="fit-content"
                    direction="row"
                    gap={1.5}
                    alignItems="center"
                    justifyContent="end"
                    position="absolute"
                    right={10}
                    top="50%"
                    sx={{
                        transform: "translateY(-50%)"
                    }}
                >
                    <Typography
                        variant="body1"
                        className="home"
                        sx={{ overflow: "unset !important" }}
                    >
                        {t("go_to")}
                    </Typography>
                    <TextField
                        sx={{
                            marginBottom: "5px",
                            display: "-webkit-inline-box",
                            width: "unset",
                            minWidth: "unset",
                            maxWidth: "80px",
                            "& input": {
                                padding: "8.5px 14px"
                            }
                        }}
                        value={filterValue}
                        onChange={(event) => {
                            handleFilterChange(event);
                        }}
                        onKeyDown={async (event) => {
                            if (event.key === "Enter") {
                                await handleFilterChange(event);
                                onEnter();
                                event.preventDefault();
                            }
                        }}
                    />
                    <Typography
                        variant="body1"
                        className="home"
                        sx={{ overflow: "unset !important" }}
                    >
                        {`/${totalPages}`}
                    </Typography>
                </Stack>
            )}
        </Stack>
    );
};

export default Pagination;
