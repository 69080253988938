import AddIcon from "@mui/icons-material/Add";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CachedIcon from "@mui/icons-material/Cached";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SaveIcon from "@mui/icons-material/Save";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";

import { IconButton } from "@mui/material";

import PropTypes from "prop-types";

import CustomTooltip from "./CustomTooltip";

/**
 * @param {object} props
 * @param {string} props.tooltip - text to be shown in Tooltip window
 * @param {string} props.icon - string with type of icon, e. g. 'edit' or 'delete'
 * @param {Function} props.handleClick - function to call when button is clicked
 * @param {string} props.buttonClass - class name for button
 * @param {object} props.buttonStyle - custom style attributes for button
 * @param {string} props.iconClass - class name for icon
 * @param {object} props.iconStyle - custom style attributes for icon
 * @param {boolean} props.disabled - boolean value to dis- or enable button
 * @param {string} props.ariaLabel - text to use for accessibility
 * @returns {JSX.Element} custom button with tooltip and pre-defined icons
 */

const TooltipButton = ({
    tooltip,
    icon,
    handleClick,
    buttonClass,
    buttonStyle,
    iconClass,
    iconStyle,
    disabled,
    ariaLabel
}) => {
    const icons = {
        add: AddIcon,
        edit: EditIcon,
        delete: DeleteIcon,
        save: SaveIcon,
        cancel: CancelIcon,
        close: CloseIcon,
        open: OpenInNewIcon,
        visibilityOff: VisibilityOffIcon,
        visibility: VisibilityIcon,
        copy: ContentCopyIcon,
        reload: CachedIcon,
        check: CheckIcon,
        settings: SettingsOutlinedIcon,
        arrowup: ArrowUpwardIcon,
        arrowdown: ArrowDownwardIcon,
        clear_filter: FilterAltOffOutlinedIcon,
        loading: AutorenewOutlinedIcon,
        options: MoreVertOutlinedIcon
    };

    return (
        <CustomTooltip title={tooltip}>
            <IconButton
                aria-label={ariaLabel}
                className={buttonClass}
                onClick={handleClick}
                sx={buttonStyle}
                disabled={disabled}
            >
                {icon === "loading" ? (
                    <AutorenewOutlinedIcon
                        sx={{
                            ...iconStyle,
                            animation: "spin 2s reverse linear infinite",
                            "@keyframes spin": {
                                "0%": {
                                    transform: "rotate(360deg)"
                                },
                                "100%": {
                                    transform: "rotate(0deg)"
                                }
                            }
                        }}
                        className={iconClass}
                    />
                ) : icon !== "loading" ? (
                    Object.entries(icons).map(
                        ([key, Component]) =>
                            icon === key && (
                                <Component key={key} sx={iconStyle} className={iconClass} />
                            )
                    )
                ) : (
                    <></>
                )}
            </IconButton>
        </CustomTooltip>
    );
};

TooltipButton.propTypes = {
    tooltip: PropTypes.string,
    icon: PropTypes.string,
    handleClick: PropTypes.func,
    buttonClass: PropTypes.string,
    buttonStyle: PropTypes.object,
    iconClass: PropTypes.string,
    iconStyle: PropTypes.object,
    disabled: PropTypes.bool,
    ariaLabel: PropTypes.string
};

export default TooltipButton;
