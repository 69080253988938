import { InputAdornment, TextField } from "@mui/material";

import React from "react";

import { useTranslation } from "react-i18next";
import TooltipButton from "./TooltipButton";

/**
 * @param {object} props
 * @param {string} props.value - The current filter value
 * @param {number} props.index - Index for changing filter value array
 * @param {function} props.onChange - Function to handle value changes
 * @param {function} props.onEnter - Function to handle pressing enter key
 * @returns {JSX.Element} text filter component
 */
const DefaultColumnFilter = ({ value, index, onChange, onEnter }) => {
  // i18n
  const { t } = useTranslation();

  return (
    <TextField
      value={value || ""}
      onChange={(e) => onChange(index, e.target.value)}
      placeholder={t("search")}
      variant="outlined"
      size="small"
      className="textfilter"
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onEnter();
          event.preventDefault();
        }
      }}
      sx={{ "& div": { paddingRight: "unset" } }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <TooltipButton
              iconStyle={{
                color: "white",
                fontSize: "1rem",
                display: value === "" ? "none" : "block"
              }}
              ariaLabel="clear filter"
              icon="cancel"
              handleClick={() => {
                onChange(index, "");
              }}
            />
          </InputAdornment>
        )
      }}
    />
  );
};

export default DefaultColumnFilter;
