import {
    Box,
    Button,
    Checkbox,
    Divider,
    InputAdornment,
    Menu,
    MenuItem,
    TextField
} from "@mui/material";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import TooltipButton from "./TooltipButton";

/**
 * @param {Object} props
 * @param {Function} props.onMenuClick - function to call when a menu item is clicked
 * @param {Object[]} props.currentColumns - array of current column objects
 * @param {Object[]} props.filterColumns - copy of current column objects
 * @param {Function} props.onReset - function to call when menu is being closed without changed applied
 * @param {Function} props.onSubmit - function to call when applying changes
 * @returns {JSX.Element} - dropdown menu to change columns currently displayed
 */
const ColumnConfiguration = ({
    onMenuClick,
    allColumns,
    currentColumns,
    filterColumns,
    onReset,
    onSubmit
}) => {
    // i18n
    const { t } = useTranslation(["translation", "fieldnames"]);

    // copy of all columns for filtering inside menu
    const [columnsCopy, setColumnsCopy] = useState([]);

    const [filterColumnsCopy, setFilterColumnsCopy] = useState([]);

    // filter value
    const [searchValue, setSearchValue] = useState("");

    // variables for menu display
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleColumnsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = (afterSubmit = false, reason) => {
        if (reason === "backdropClick") {
            setTimeout(() => {
                setFilterColumnsCopy(currentColumns);
            }, 500);
        }
        setAnchorEl(null);
        setColumnsCopy(allColumns);
        if (!afterSubmit) {
            setTimeout(() => {
                onReset();
            }, 1000);
        }
    };

    useEffect(() => {
        setColumnsCopy(allColumns);
    }, [allColumns]);

    useEffect(() => {
        setFilterColumnsCopy(filterColumns);
    }, [filterColumns]);

    const handleChange = (event) => {
        const value = typeof event === "string" ? event : event.target.value;
        setSearchValue(value);
        setColumnsCopy(
            columnsCopy.filter((column) => {
                return (
                    t(column["FieldName"], { ns: "fieldnames" })
                        .toLowerCase()
                        .includes(value.toLowerCase()) || isChecked(column)
                );
            })
        );
        if (value === "") {
            setColumnsCopy(allColumns);
        }
    };

    const isChecked = (column) => {
        return filterColumnsCopy.some((current) => {
            return current.FieldName === column.FieldName;
        });
    };

    return (
        <>
            {allColumns ? (
                <>
                    <TooltipButton
                        tooltip={t("configure_columns")}
                        icon={"settings"}
                        handleClick={handleColumnsClick}
                        buttonStyle={{ height: "min-content" }}
                        iconStyle={{ color: "white" }}
                    />
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            "aria-labelledby": "long-button"
                        }}
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={(event, reason) => {
                            handleCloseMenu(false, reason);
                        }}
                        slotProps={{
                            paper: {
                                style: {
                                    height: "auto",
                                    width: "max-content"
                                }
                            }
                        }}
                    >
                        {allColumns && allColumns.length === 0 ? (
                            <MenuItem
                                disableRipple
                                disableTouchRipple
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "unset"
                                    }
                                }}
                            >
                                ...
                            </MenuItem>
                        ) : allColumns && allColumns.length > 0 ? (
                            <Box>
                                <MenuItem
                                    disableRipple
                                    disableTouchRipple
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "unset"
                                        }
                                    }}
                                >
                                    <TextField
                                        variant="standard"
                                        value={searchValue}
                                        onInput={(event) => {
                                            event.preventDefault();
                                            handleChange(event);
                                        }}
                                        placeholder={t("search")}
                                        sx={{ "& input": { color: "black", fontSize: "16px" } }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <TooltipButton
                                                        icon="cancel"
                                                        iconStyle={{ fontSize: "16px" }}
                                                        handleClick={() => handleChange("")}
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    ></TextField>
                                </MenuItem>
                                <Divider />
                                <div style={{ height: 200, overflowY: "scroll" }}>
                                    {columnsCopy &&
                                        columnsCopy.map((column) => (
                                            <MenuItem
                                                key={column.FieldName}
                                                onClick={() => onMenuClick(column)}
                                            >
                                                <>
                                                    <Checkbox
                                                        sx={{
                                                            "&.Mui-disabled": { color: "#1976d2" }
                                                        }}
                                                        disabled
                                                        checked={isChecked(column)}
                                                    />
                                                    {t(column.FieldName, { ns: "fieldnames" })}
                                                </>
                                            </MenuItem>
                                        ))}
                                </div>
                                <Divider />
                                <MenuItem
                                    disableRipple
                                    disableTouchRipple
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "unset"
                                        }
                                    }}
                                >
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        disabled={filterColumns.length === 0}
                                        onClick={() => {
                                            onSubmit();
                                            handleCloseMenu(true);
                                        }}
                                    >{`${t("apply")} (${filterColumns.length} / 6)`}</Button>
                                </MenuItem>
                            </Box>
                        ) : (
                            <MenuItem
                                disableRipple
                                disableTouchRipple
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "unset"
                                    }
                                }}
                            >
                                {t("no_columns")}
                            </MenuItem>
                        )}
                    </Menu>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default ColumnConfiguration;
