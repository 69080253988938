import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import TooltipButton from "./TooltipButton";
import ConfirmDialog from "./ConfirmDialog";

/**
 * @param {Object} props
 * @param {object} props.data - invoice data to be shown as row on homepage
 * @param {Function} props.onReject - function to call when rejecting invoice
 * @returns {JSX.Element} - dropdown menu to open options
 */
const InvoiceOptions = ({ data, onReject }) => {
    // i18n
    const { t } = useTranslation();

    // variables for menu display
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    // variables for confirm dialog display
    const [dialogOpen, toggleDialogOpen] = useState(false);
    const [dialogHeadline, setDialogHeadline] = useState("");
    const [dialogQuestion, setDialogQuestion] = useState("");

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = (event, reason) => {
        if (event) {
            event.preventDefault();
        }
        setAnchorEl(null);
    };

    const handleOpenDialog = (headline, question) => {
        setDialogHeadline(headline);
        setDialogQuestion(question);
        toggleDialogOpen(true);
    };

    const handleCloseDialog = (event) => {
        event.preventDefault();
        toggleDialogOpen(false);
        setTimeout(() => {
            setDialogHeadline("");
            setDialogQuestion("");
        }, 100);
    };

    const handleReject = () => {
        onReject(data?.["DocumentId"]);
        handleCloseMenu();
    };

    return (
        <>
            <TooltipButton
                icon="options"
                buttonStyle={{ padding: "4px" }}
                iconStyle={{ color: "white", fontSize: "20px" }}
                handleClick={(event) => {
                    event.preventDefault();
                    handleOpenMenu(event);
                }}
                tooltip={t("options")}
            />
            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button"
                }}
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={(event, reason) => {
                    handleCloseMenu(event, reason);
                }}
                slotProps={{
                    paper: {
                        style: {
                            height: "auto",
                            minWidth: "min-content",
                            width: "12%"
                        }
                    }
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem
                    onClick={(event) => {
                        event.preventDefault();
                        handleOpenDialog(
                            t("invoice_reject_headline"),
                            t("invoice_reject_question", { prop: data?.["InvoiceNumber"] })
                        );
                    }}
                    disabled={data?.["FinishTimestamp"] !== "None"}
                >
                    <>
                        <ListItemIcon>
                            <DoNotDisturbAltOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        {t("reject_invoice")}
                    </>
                </MenuItem>
            </Menu>
            <ConfirmDialog
                isOpen={dialogOpen}
                headline={dialogHeadline}
                question={dialogQuestion}
                handleClose={handleCloseDialog}
                handleConfirm={handleReject}
            />
        </>
    );
};

export default InvoiceOptions;
