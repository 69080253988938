import { FormControl, MenuItem, Select } from "@mui/material";

import React from "react";

import { useTranslation } from "react-i18next";

/**
 * @param {object} props
 * @param {string} props.value - The current filter value
 * @param {number} props.index - Index for changing filter value array
 * @param {function} props.onChange - Function to handle value changes
 * @param {function} props.onEnter - Function to handle pressing enter key
 * @returns {JSX.Element} selection to filter invoices based on their status
 */
const StatusFilter = ({ value, index, onChange, onEnter }) => {
    // i18n
    const { t } = useTranslation("fieldnames");

    const statusOptions = [
        { value: "100", label: t("status_100") }, // "Initial"
        { value: "101", label: t("status_101") }, // "Accepted"
        { value: "102", label: t("status_102") }, // "Waiting for Transfer"
        { value: "200", label: t("status_200") }, // "Processed"
        { value: "201", label: t("status_201") }, // "Rejected (SAP)"
        { value: "400", label: t("status_400") }, // "Rejected"
        { value: "401", label: t("status_401") }, // "Blocked (Waiting for Transfer)"
        { value: "402", label: t("status_402") }, // "Blocked (Rejected)"
        { value: "500", label: t("status_500") } // "Error"
    ];

    const handleChange = async (index, event) => {
        await onChange(index, event.target.value);
        onEnter();
    };

    return (
        <FormControl variant="outlined" size="small" fullWidth>
            <Select
                value={value || ""}
                displayEmpty
                sx={{
                    borderRadius: "4px",
                    marginTop: "8px",
                    minWidth: 120
                }}
                onChange={(event) => {
                    handleChange(index, event);
                }}
            >
                <MenuItem value="">
                    <span style={{ opacity: "0.5" }}>Status</span>
                </MenuItem>
                {statusOptions.map((status, i) => (
                    <MenuItem key={i} value={status.value}>
                        {status.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default StatusFilter;
