import { InputAdornment, Stack, TextField } from "@mui/material";

import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import i18n from "i18next";
import { useTranslation } from "react-i18next";

import TooltipButton from "./TooltipButton";

/**
 * @param {object} props
 * @param {string[]} props.value - The current filter value(s)
 * @param {number} props.index -Index for changing filter value array
 * @param {Function} props.onChange - Function to handle value changes
 * @param {function} props.onEnter - Function to handle pressing enter key
 * @returns {JSX.Element} date picker to filter invoices based on receipt date
 */
const DateRangeColumnFilter = ({ value, index, onChange, onEnter }) => {
  // i18n
  const { t } = useTranslation();

  const [startDate, endDate] = Array.isArray(value) ? value : ["", ""];

  const formatDate = (date) => (date ? date.toDateString() : null);

  const handleChange = (date) => {
    onChange(index, [date[0], date[1]]);
    onEnter();
  };

  const handleClear = (event, date) => {
    event.stopPropagation();
    handleChange(date);
  };

  const deDays = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
  const deMonths = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
  ];

  const de = {
    localize: {
      day: (n) => deDays[n],
      month: (n) => deMonths[n]
    },
    formatLong: {
      date: () => "dd.MM.yy"
    }
  };

  const isDE = () => {
    return i18n.language === "de";
  };

  return (
    <>
      <Stack direction="row" alignItems="end" width="100%">
        <DatePicker
          selected={startDate}
          locale={isDE() ? de : ""}
          dateFormat={isDE() ? "dd.MM.yy" : "MM/dd/yy"}
          onChange={(date) => {
            handleChange([formatDate(date), endDate]);
          }}
          calendarStartDay={1}
          maxDate={endDate || new Date()}
          placeholderText={t("start_date")}
          customInput={
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              className="datepicker"
              sx={{
                marginRight: { lg: "5px", xl: "10px" },
                "& div.MuiInputBase-root": { paddingRight: "unset" }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TooltipButton
                      iconStyle={{
                        color: "white",
                        fontSize: "1rem",
                        display: startDate === "" || startDate === undefined ? "none" : "block"
                      }}
                      ariaLabel="clear date"
                      icon="cancel"
                      handleClick={(event) => {
                        handleClear(event, ["", endDate]);
                      }}
                    />
                  </InputAdornment>
                )
              }}
            />
          }
        />
        <DatePicker
          selected={endDate}
          locale={isDE() ? de : ""}
          dateFormat={isDE() ? "dd.MM.yy" : "MM/dd/yy"}
          onChange={(date) => {
            handleChange([startDate, formatDate(date)]);
          }}
          calendarStartDay={1}
          minDate={startDate}
          maxDate={new Date()}
          placeholderText={t("end_date")}
          customInput={
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              className="datepicker"
              sx={{
                marginLeft: { lg: "5px", xl: "10px" },
                "& div.MuiInputBase-root": { paddingRight: "unset" }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TooltipButton
                      iconStyle={{
                        color: "white",
                        fontSize: "1rem",
                        display: endDate === "" || endDate === undefined ? "none" : "block"
                      }}
                      ariaLabel="clear date"
                      icon="cancel"
                      handleClick={(event) => {
                        handleClear(event, [startDate, ""]);
                      }}
                    />
                  </InputAdornment>
                )
              }}
            />
          }
        />
      </Stack>
    </>
  );
};

export default DateRangeColumnFilter;
