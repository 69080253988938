import { Grid, Skeleton, Stack, Typography, Button } from "@mui/material";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import { useAuth } from "../AuthProvider";

import InvoiceDetail from "../components/InvoiceDetails";
import VisualFrame from "../components/VisualFrame";

/**
 * @returns {JSX.Element} shows overview of certain invoice based on url param
 */

const InvoiceOverview = () => {
    // i18n
    const { t } = useTranslation();
    // authorization
    const { request } = useAuth();

    // invoice number from URL parameter
    const { documentId } = useParams();

    // endpoints in use
    const endpoint = `${process.env.REACT_APP_BACKEND_URL}/invoices/${documentId}`;
    const endpoint_unblock = `${process.env.REACT_APP_BACKEND_URL}/unblock/${documentId}`;
    const endpoint_logs = `${process.env.REACT_APP_BACKEND_URL}/logs/${documentId}`;

    // details of certain invoice
    const [loading, setLoading] = useState(false);
    const [loadingLogs, setLoadingLogs] = useState(false);
    const [full, setFull] = useState(null);

    const [data, setData] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [visualization, setVisualization] = useState({ data: "", mimeType: "" });
    const [logs, setLogs] = useState([]);

    const fetchData = async (init = false) => {
        if (init) {
            setLoading(true);
        }

        const response = await request("get", endpoint, null, null, [
            {
                statusCode: 400,
                type: "error",
                message: t("load_failure", { prop: "Details" })
            }
        ]);

        if (response?.status === 200) {
            setData(response.data);
            fetchLogs();
            if (response.data?.[0]?.length > 0) {
                setFull(true);
            } else {
                setFull(false);
            }
            setTimeout(() => {
                setLoading(false);
                setLoadingLogs(false);
            }, 1000);
        }
    };

    const fetchLogs = async () => {
        setLoadingLogs(true);
        const response = await request("get", endpoint_logs, null, null, []);

        if (response?.status === 200 || response?.status === 204) {
            setLogs(response.data);
        }
    };

    const unblockDocument = async () => {
        const body = {
            document_id: documentId
        };

        const response = await request("put", endpoint_unblock, body, "application/json", [
            {
                statusCode: 200,
                type: "success",
                message: t("unblock_document_success")
            },
            {
                statusCode: 400,
                type: "error",
                message: t("unblock_document_failure")
            }
        ]);

        if (response?.status === 200) {
            fetchData();
        }
    };

    const updateVisualization = async (d) => {
        if (d.some((item) => item["AttachmentName"] === "Visualization.html")) {
            setVisualization(() => ({
                data: d.find((item) => item["AttachmentName"] === "Visualization.html")[
                    "AttachmentData"
                ],
                mimeType: "text/html"
            }));
        } else if (d.some((item) => item["AttachmentName"] === "ORIGINAL_ocr.pdf")) {
            setVisualization(() => ({
                data: d.find((item) => item["AttachmentName"] === "ORIGINAL_ocr.pdf")[
                    "AttachmentData"
                ],
                mimeType: "application/pdf"
            }));
        } else {
            setVisualization(null);
        }
    };

    // function to filter out only certain keys from object to be shown
    const filterDataByFieldNames = (dataArray, filterKeys) => {
        if (dataArray) {
            return dataArray.filter((obj) => filterKeys.includes(obj.FieldName));
        }
    };

    useEffect(() => {
        fetchData(true);
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            setAttachments(data[3]);
        }
    }, [data]);

    useEffect(() => {
        if (attachments.length > 0) {
            updateVisualization(attachments);
        }
    }, [attachments]);

    return (
        <Stack direction="column" spacing={1} sx={{ alignSelf: "center", minWidth: "100%" }}>
            {/* heading and button container */}
            <Stack direction="row" alignItems="center" spacing={2}>
                {/* heading */}
                <Typography variant="h1" className="pageHeadline">
                    {!loading
                        ? data[0] & (data[0] !== undefined)
                            ? `${t("overview_title")} ${
                                  data[0]
                                      ? filterDataByFieldNames(data[0], ["InvoiceNumber"])[0][
                                            "FieldValue"
                                        ]
                                      : ""
                              } - Details`
                            : t("overview_title")
                        : t("loading_invoice")}
                </Typography>

                {/* button */}
                {(!loading && data?.[5]?.[0]?.DocumentStatus === "401") ||
                    (data?.[5]?.[0]?.DocumentStatus === "402" && (
                        <Button
                            className="invAttachment unblock"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                unblockDocument();
                            }}
                        >
                            {t("unblock")}
                        </Button>
                    ))}
            </Stack>

            {full !== null && full ? (
                // grid container
                <Grid
                    container
                    spacing={8}
                    sx={{
                        marginLeft: "-64px !important",
                        marginTop: "-32px !important"
                    }}
                >
                    {/* container for invoice details */}
                    <Grid item md={6} xl={7}>
                        <Grid container spacing={4}>
                            {data[0] && (
                                //billing details
                                <Grid item md={12} xl={7}>
                                    <InvoiceDetail
                                        loading={loading}
                                        headline="billing_details"
                                        translation={t}
                                        data={filterDataByFieldNames(data[0], [
                                            "InvoiceNumber",
                                            "DocDate",
                                            "SellerName",
                                            "SellerVAT",
                                            "PurchaseOrder",
                                            "DeliveryNote"
                                        ])}
                                    />
                                </Grid>
                            )}

                            {/* financial details */}
                            <Grid item md={12} xl={5}>
                                <InvoiceDetail
                                    loading={loading}
                                    headline="financials"
                                    translation={t}
                                    data={filterDataByFieldNames(data[0], [
                                        "Currency",
                                        "GrossAmount",
                                        "NetAmount",
                                        "TotalTaxAmount"
                                    ])}
                                />
                            </Grid>

                            {/* invoice items */}
                            <Grid item md={12}>
                                <InvoiceDetail
                                    loading={loading}
                                    headline="invoice_items"
                                    translation={t}
                                    items={filterDataByFieldNames(data[1], [
                                        "InvoiceLineID",
                                        "ItemNetPrice",
                                        "LineQuantity",
                                        "LineAmount",
                                        "ItemTaxRate",
                                        "LineTaxRate"
                                    ])}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <InvoiceDetail
                                    loading={loading}
                                    headline="linkhead"
                                    translation={t}
                                    links={data[4]}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <InvoiceDetail
                                    loading={loading}
                                    headline="attachments"
                                    translation={t}
                                    attachments={attachments}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <InvoiceDetail
                                    loading={loadingLogs}
                                    headline="Logs"
                                    translation={t}
                                    logs={logs}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} xl={5}>
                        {!loading && visualization ? (
                            <VisualFrame visualization={visualization} />
                        ) : (
                            <Skeleton
                                variant="rounded"
                                sx={{
                                    borderRadius: "50px",
                                    height: "100%",
                                    width: "100%"
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            ) : (full !== null) & !full ? (
                <InvoiceDetail
                    loading={loading}
                    headline="attachments"
                    translation={t}
                    attachments={attachments}
                />
            ) : (
                <></>
            )}
        </Stack>
    );
};

export default InvoiceOverview;
